

























































const style = {
  style: {
    base: {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
      "::placeholder": {
        fontWeight: "normal",
        color: "rgba(0, 0, 0, 0.35)"
      }
    },
    invalid: {
      color: "#ff4848"
    }
  }
};
import { Component, Vue } from "vue-property-decorator";
import InputCard from "@/components/base/InputCard.vue";

import {
  loadStripe,
  PaymentMethod,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeError
} from "@stripe/stripe-js";
import { Action, Getter } from "vuex-class";
import {
  PaymentImpl,
  PayoutResponseImpl,
  VerificationPaymentImpl
} from "@/models/Payout.model";
import { ProductImpl } from "@/models/Product.model";
import UserRepository from "@/repository/modules/user.repository";

import ButtonPaypal from "./buttonPaypal.vue";
@Component({
  components: {
    InputCard,
    ButtonPaypal
  }
})
export default class PaymentSection extends Vue {
  @Getter(`productStore/GET_PRICE`) servicePrice!: string;
  @Getter(`productStore/GET_PRODUCT`) productObject!: ProductImpl;
  @Getter(`solicitudStore/GET_ID_USER`) userId!: string;
  @Action(`solicitudStore/FINALICE_PROCESS`) processSuccess: any;

  stripe!: Stripe;
  card!: StripeCardNumberElement;
  cardExpiration!: StripeCardExpiryElement;
  cardCvc!: StripeCardCvcElement;

  isRunPayment = false;
  anyError: string | null = null;

  isRunPaymentPaypal = false;
  anyErrorPaypal: string | null = null;

  cardActive = false;
  cardDateActive = false;
  cardCvcActive = false;

  cardError = false;
  dateError = false;
  cvcError = false;

  cardComplete = false;
  cardDateComplete = false;
  cardCvcComplete = false;

  dateEmpty = true;
  cardEmpty = true;
  cvcEmpty = true;

  scriptPaypal!: HTMLElement;

  async mounted() {
    this.confStripe();
  }

  async confStripe() {
    const mstripe = await loadStripe(
      process.env.VUE_APP_STRIPE_API_TOKEN_PUBLIC
    );
    this.stripe = mstripe as Stripe;
    const elements = this.stripe.elements();
    this.card = elements.create("cardNumber", style);
    this.cardExpiration = elements.create("cardExpiry", style);
    this.cardCvc = elements.create("cardCvc", style);
    this.card.mount("#card-element");
    this.cardExpiration.mount("#card-expiry");
    this.cardCvc.mount("#card-cvc");
    this.card
      .on("focus", () => {
        this.cardActive = true;
      })
      .on("blur", () => {
        this.cardActive = false;
      })
      .on("change", ({ error, empty, complete }) => {
        this.cardComplete = complete;
        this.cardEmpty = empty;
      });

    this.cardExpiration
      .on("focus", () => {
        this.cardDateActive = true;
      })
      .on("blur", () => {
        this.cardDateActive = false;
      })
      .on("change", ({ error, empty, complete }) => {
        this.cardDateComplete = complete;
        this.dateEmpty = empty;
      });

    this.cardCvc
      .on("focus", () => {
        this.cardCvcActive = true;
      })
      .on("blur", () => {
        this.cardCvcActive = false;
      })
      .on("change", ({ error, empty, complete }) => {
        this.cardCvcComplete = complete;
        this.cvcEmpty = empty;
      });
  }

  async initPurchase() {
    if (!this.isRunPayment && !this.isButtonPaymentDisabled) {
      this.isRunPayment = true;
      this.anyError = null;
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.card
      });
      if (error) this.serverErrorsPayment(error.type);
      if (paymentMethod) await this.initPayment("stripe", paymentMethod.id);
    }
  }

  async initPaymentPaypal(dataPaypal: any) {
    this.isRunPaymentPaypal = true;
    this.anyErrorPaypal = null;
    this.initPayment("paypal", dataPaypal.orderID);
  }

  async initPayment(type: "stripe" | "paypal", token: string) {
    const paymentData: PaymentImpl = {
      user_id: this.userId,
      product_id: this.productObject._id,
      payment_method: token,
      payment_source: type
    };
    UserRepository.pagoSolicitud(paymentData)
      .then(({ data }) => this.processPayment(data.payout))
      .catch(error => {
        console.log("store error", error.response);
        this.serverErrorsPayment(null);
      });
  }

  async processPayment(paymentResponse: PayoutResponseImpl) {
    if (
      paymentResponse.next_action &&
      paymentResponse.status === "requires_action"
    ) {
      const { paymentIntent } = await this.stripe.confirmCardPayment(
        paymentResponse.client_secret
      );
      if (!paymentIntent) {
        this.serverErrorsPayment(null);
      } else {
        //TODO : resposnse
        const verificationPayment: VerificationPaymentImpl = {
          user_id: this.userId,
          product_id: this.productObject._id,
          payment_id: paymentIntent.id,
          payment_method: paymentIntent.payment_method as string,
          payment_source: "stripe"
        };
        UserRepository.verificarPagoSolicitud(verificationPayment)
          .then(({ data }) => this.processPayment(data.payout))
          .catch(error => {
            console.log("store error", error.message);
            console.log("store error", error.data);
            console.log("store error", error.data.message);
          });
      }
    } else if (
      paymentResponse.next_action == null &&
      paymentResponse.status === "succeeded"
    ) {
      this.isRunPayment = false;
      this.isRunPaymentPaypal = false;
      this.processSuccess();
    } else {
      this.paymentResponseError(paymentResponse);
    }
  }

  errorPurchasePaypal(error: any) {
    this.serverErrorsPayment(null);
  }

  paymentResponseError(result: PayoutResponseImpl) {
    this.serverErrorsPayment(result.error_code);
  }

  serverErrorsPayment(error_code: string | null) {
    this.isRunPayment = false;
    this.isRunPaymentPaypal = false;
    switch (error_code) {
      case "incorrect_cvc":
        this.anyError = "El código CVC es incorrecto";
        break;
      case "card_declined":
        this.anyError = "Su tarjeta ha sido rechazada";
        break;
      case "expired_card":
        this.anyError = "Su tarjeta ha sido rechazada";
        break;
      case "incorrect_number":
        this.anyError = "Su tarjeta es incorrecta";
        break;
      default:
        this.anyError = "Ha ocurrido un error inesperado..";
        break;
    }
  }

  get isButtonPaymentDisabled() {
    if (
      this.cardError === false &&
      this.dateError === false &&
      this.cvcError === false &&
      this.cardEmpty === false &&
      this.dateEmpty === false &&
      this.cvcEmpty === false &&
      this.cardComplete === true &&
      this.cardDateComplete === true &&
      this.cardCvcComplete === true
    ) {
      return false;
    } else {
      return true;
    }
  }
}
