











import { Component, Vue } from "vue-property-decorator";
@Component
export default class FinalSection extends Vue {
  beforeMount() {
    this.$ga.event({
      eventCategory: "Conversion",
      eventAction: "conversion"
    });
  }
}
