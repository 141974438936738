<template lang="pug">
div
  #paypal-button-checkout(v-show="!isLoading")
  v-btn.mb-2(
    v-show="isLoading",
    loading,
    color="amber",
    large,
    depressed,
    block
  )
</template>
<script>
export default {
  props: {
    price: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      script: null,
    };
  },
  mounted() {
    this.addScript();
  },
  destroyed() {
    this.removeScript();
  },
  methods: {
    addScript() {
      this.script = document.createElement("script");
      this.script.type = "text/javascript";
      this.script.src =
        `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_API_TOKEN_PUBLIC}` +
        `&disable-funding=credit,card,venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24,sofort` +
        `&currency=${this.currency}`;
      this.script.onload = () => this.loadButtonPaypal();
      document.head.appendChild(this.script);
    },
    removeScript() {
      this.script.remove();
    },
    loadButtonPaypal() {
      this.$nextTick(() => {
        window.paypal
          .Buttons({
            style: {
              color: "gold",
              shape: "rect",
              label: "paypal",
              height: 43,
              tagline: false,
            },
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: this.price.toString(),
                    },
                  },
                ],
                application_context: {
                  brand_name: "Solicitud Vida Laboral",
                  shipping_preference: "NO_SHIPPING",
                  user_action: "PAY_NOW",
                },
              });
            },
            onApprove: (info) => {
              this.$emit("ApprovePurchase", info);
            },
            onCancel: (info) => {
              console.log("canceled");
            },
            onError: (error) => {
              this.$emit("ErrorPurchase", error);
            },
          })
          .render("#paypal-button-checkout");
      });
    },
  },
};
</script>