






















import { Component, Vue } from "vue-property-decorator";
import BaseMaterialOverlays from "@/components/base/MaterialOverlays.vue";

import FormularySection from "@/components/Formulario/FormularySection.vue";
import PaymentSection from "@/components/Formulario/PaymentSection.vue";
import FinalSection from "@/components/Formulario/FinalSection.vue";
import { FormularioSolicitudImpl } from "@/models/Formulario.model";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    BaseMaterialOverlays,
    FormularySection,
    PaymentSection,
    FinalSection
  }
})
export default class Formulario extends Vue {
  @Getter("solicitudStore/GET_STEP") step!: number;
  @Action(`solicitudStore/SUBMIT_FORM`) submitForm: any;
}
