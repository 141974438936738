





























































































































import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import tipoVias from "@/assets/tipo_vias_es.json";

import {
  FormularioSolicitudImpl,
  FormularioSolicitudVias
} from "@/models/Formulario.model";
import { Getter, Mutation } from "vuex-class";

@Component
export default class FormularySection extends Vue {
  @Getter(`formularyStore/GET_FORM`) formulary!: FormularioSolicitudImpl;
  @Mutation(`formularyStore/SET_FORM`) setForm: any;

  created() {
    if (this.formulary) this.form = this.formulary;
  }

  form: FormularioSolicitudImpl = {
    formValid: true,
    formName: "",
    formPostName: "",
    formEmail: "",
    formTypeDocument: "DNI",
    formDocumentNum: "",
    formNumberSs: "",
    formDomicilioVia: { name: "", short: "" },
    formDomicilioCalle: "",
    formDomicilioNum: "",
    formDomicilioPiso: "",
    formDomicilioPuerta: "",
    formDomicilioEscalera: "",
    formDomicilioBloque: "",
    formDomicilioLocalidad: "",
    formDomicilioCodigoPostal: "",
    formCheckbox: false
  };

  rules_name: {}[] = [(v: string) => !!v || "Tú nombre es necesario"];
  rules_post_name: {}[] = [
    (v: string) => !!v || "Tus apellidos son necesarios"
  ];
  rules_document_num: {}[] = [
    (v: string) => !!v || "Tú documento identificativo es necesario"
  ];
  rules_number_ss: {}[] = [
    (v: string) => !!v || "Tú número de la seguridad social es necesario"
  ];
  rules_domicilio_via: {}[] = [
    (v: FormularioSolicitudVias) => !!v.name || "El tipo de via es necesaria"
  ];

  rules_domicilio_calle: {}[] = [
    (v: string) => !!v || "El nombre de la via es necesario"
  ];
  rules_domicilio_num: {}[] = [(v: string) => !!v];
  rules_domicilio_localidad: {}[] = [
    (v: string) => !!v || "La localidad es necesaria"
  ];
  rules_domicilio_codigo_postal: {}[] = [
    (v: string) => !!v || "El codigo postal es necesario"
  ];
  rules_checkbox: {}[] = [(v: boolean) => !!v];

  rules_email: {}[] = [
    (v: string) => (!!v && this.ValidateEmail(v)) || "El email no es valido"
  ];

  @Watch("form", { deep: true })
  onPropertyChange(value: FormularioSolicitudImpl) {
    this.setForm(value);
  }

  validate() {
    const result: boolean = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (result) {
      this.sendForm();
    }
  }

  get labelTypeDocument() {
    if (this.form.formTypeDocument == "DNI") {
      return "*Número de DNI";
    } else {
      return "*Número de NIE";
    }
  }
  get vias(): FormularioSolicitudVias[] {
    return tipoVias;
  }

  @Emit()
  sendForm() {
    return this.form;
  }

  ValidateEmail = (mail: string) =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    );
}
